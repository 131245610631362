import React from "react"
import { Header, Container } from "semantic-ui-react";
import { MainHeader } from "../helpers/styledComponents";
import styled from "styled-components";
import Layout from "../components/Layout";
import OneTrustContent from "../components/OneTrustContent";
import { Helmet } from "react-helmet";

const PageContainer = styled(Container)`
`;

export default ()=>(
  <Layout>
    <Helmet>
      <title>Privacy | Full Story Initiative</title>
      <meta name="title" content="Privacy | Full Story Initiative" />
      <meta name="og:title" content="Privacy | Full Story Initiative" />
      <meta name="twitter:title" content="Privacy | Full Story Initiative" />
      <meta name="og:site_name" content="Privacy | Full Story Initiative" />
    </Helmet>
    <PageContainer>
      <MainHeader>
        <Header className="h1 compact" content="Privacy" />
      </MainHeader>
      <OneTrustContent 
        id="otnotice-cc255338-efbd-4752-94d6-209c7197cc24"
        url="https://privacyportal-cdn.onetrust.com/722df920-f42e-4d91-b843-6306f9a6a5a0/privacy-notices/cc255338-efbd-4752-94d6-209c7197cc24.json"
      />
    </PageContainer>
  </Layout>
);
